import { FilterDocumentCatalogue, ItemCatalogueExpedienteCar } from '@core/models/expediente-car.model';
import { createAction, props } from '@ngrx/store';

export const initCatalogueExpedienteDocs = createAction('[Ccatalogue docs expediente API] Load init');
export const loadSuccessCatalogueExpedienteDocs = createAction(
  '[Ccatalogue docs expediente API] Loaded success',
  props<{ catalogueDocumentsExpendediente: ItemCatalogueExpedienteCar[] }>()
);

export const loadFilterDocuments = createAction(
  '[Filter documents expediente] Load filter',
  props<{ filterDocuments: FilterDocumentCatalogue }>()
);

export const resetStore = createAction('[RESET] STORE ');
